import { createVM, show, hide } from './util';

let $vm;

const plugin = {
  install(Vue) {
    if (!$vm) {
      $vm = createVM(Vue);
    }

    const alert = {
      show(options = {}) {
        return show.call(this, $vm, options);
      },
      hide() {
        return hide.call(this, $vm);
      },
      isVisible() {
        return $vm.showValue;
      },
    };

    if (!Vue.$vux) {
      Vue.$vux = {
        alert,
      };
    } else {
      Vue.$vux.alert = alert;
    }

    Vue.mixin({
      created() {
        this.$vux = Vue.$vux;
      },
    });
  },
};

export default plugin;
export const { install } = plugin;

