<template>
  <div
    class="vux-selector weui-cell"
    :class="{
      'weui-cell_select': !readonly,
      'weui-cell_select-after': title
    }"
  >
    <div
      v-if="title"
      class="weui-cell__hd"
    >
      <label
        :for="`vux-selector-${uuid}`"
        class="weui-label"
        :class="labelClass"
        :style="cleanStyle({
          width: $parent.labelWidth,
          textAlign: $parent.labelAlign,
          marginRight: $parent.labelMarginRight
        })"
        v-html="title"
      ></label>
    </div>
    <div
      v-if="!readonly"
      class="weui-cell__bd"
    >
      <select
        :id="`vux-selector-${uuid}`"
        v-model="currentValue"
        class="weui-select"
        :name="name"
        :style="cleanStyle({
          direction: direction,
          color: color
        })"
      >
        <option
          v-if="showPlaceholder"
          :value="value === null ? 'null' : ''"
          :selected="isEmptyValue(value) && !!placeholder"
        >
          {{ placeholder }}
        </option>
        <v-no-ssr>
          <option
            v-if="fixIos"
            disabled
          ></option>
        </v-no-ssr>
        <option
          v-for="one in processOptions"
          :key="one.key"
          :value="one.key"
        >
          {{ direction === 'rtl' ? one.value + '&#x200E;' : one.value }}
        </option>
      </select>
    </div>
    <div
      v-else
      class="weui-cell__ft vux-selector-readonly"
    >
      {{ value | findByKey(processOptions) }}
    </div>
  </div>
</template>

<script>
import uuidMixin from '../../mixins/uuid';
import cleanStyle from '../../libs/clean-style';

const findByKey = function (key, options) {
  const _rs = Array.prototype.find.call(options, (item) => {
    return item.key === key;
  });
  return _rs ? _rs.value : key;
};

export default {
  name: 'Selector',
  filters: {
    findByKey,
  },
  mixins: [uuidMixin],
  props: {
    title: String,
    direction: String,
    options: {
      type: Array,
      required: true,
    },
    name: String,
    placeholder: String,
    readonly: Boolean,
    value: [String, Number, Object, Boolean],
    valueMap: {
      type: Array,
      validator(val) {
        if (!val.length || val.length !== 2) {
          if (process.env.NODE_ENV === 'development') {
            console.error('[VUX error] selector prop:valueMap\'s length should be 2');
          }
          return false;
        }
        return true;
      },
    },
  },
  data() {
    return {
      currentValue: '',
      isIOS: false,
    };
  },
  computed: {
    fixIos() {
      return !this.placeholder && this.isEmptyValue(this.value) && this.isIOS && this.title;
    },
    color() {
      return this.showPlaceholder ? '#A9A9A9' : '';
    },
    processOptions() {
      if (!this.options.length) {
        return [];
      }

      const isObject = typeof this.options[0] === 'object';

      if (isObject && !this.valueMap) {
        return this.options;
      }

      if (isObject && this.valueMap) {
        return this.options.map(item => {
          return {
            key: item[this.valueMap[0]],
            value: item[this.valueMap[1]],
          };
        });
      }

      return this.options.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    showPlaceholder() {
      if (this.isEmptyValue(this.value) && this.placeholder) {
        return true;
      }
      return false;
    },
    labelClass() {
      if (!this.$parent) return {};
      return {
        'vux-cell-justify': this.$parent.labelAlign === 'justify' || this.$parent.$parent.labelAlign === 'justify',
      };
    },
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      this.$emit('input', val);
      this.$emit('on-change', val);
    },
  },
  created() {
    if (typeof this.value !== 'undefined') {
      this.currentValue = this.value;
    }
  },
  beforeMount() {
    this.isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent);
  },
  methods: {
    isEmptyValue(val) {
      return typeof val === 'undefined' || val === '' || val === null;
    },
    cleanStyle,
    getFullValue() {
      if (!this.value) {
        return null;
      }
      if (!this.options.length) {
        return null;
      }
      if (typeof this.options[0] !== 'object') {
        return this.value;
      } else if (!this.valueMap) {
        return this.options.filter(one => one.key === this.value);
      } else {
        return this.options.filter(one => one[this.valueMap[0]] === this.value);
      }
    },
  },
};
</script>

<style lang="less">
@import '../../styles/weui/widget/weui_cell/weui_access';
@import '../../styles/weui/widget/weui_cell/weui_cell_global';
@import '../../styles/weui/widget/weui_cell/weui_form/weui_form_common';
@import '../../styles/weui/widget/weui_cell/weui_form/weui_select';

.vux-selector-no-padding {
  padding-left: 0;
}
.vux-selector.weui-cell_select {
  padding: 0;
}
.vux-selector.weui-cell_select-after {
  padding-left: 15px;
}
.vux-selector-readonly {
  width: 100%;
}
</style>
