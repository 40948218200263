<template>
  <div
    class="vux-search-box"
    :class="{ 'vux-search-fixed':isFixed }"
    :style="{ top: isFixed ? top : '', position: fixPosition }"
  >
    <div
      class="weui-search-bar"
      :class="{'weui-search-bar_focusing': !isCancel || currentValue}"
    >
      <slot name="left"></slot>
      <form
        class="weui-search-bar__form"
        action="."
        @submit.prevent="$emit('on-submit', value)"
      >
        <label
          v-show="!isFixed && autoFixed"
          :for="`search_input_${uuid}`"
          class="vux-search-mask"
          @click="touch"
        ></label>
        <div class="weui-search-bar__box">
          <i class="weui-icon-search"></i>
          <input
            :id="`search_input_${uuid}`"
            ref="input"
            v-model="currentValue"
            type="search"
            autocomplete="off"
            class="weui-search-bar__input"
            :placeholder="placeholder"
            :required="required"
            @focus="onFocus"
            @blur="onBlur"
            @compositionstart="onComposition($event, 'start')"
            @compositionend="onComposition($event, 'end')"
            @input="onComposition($event, 'input')"
          >
          <a
            v-show="currentValue"
            href="javascript:"
            class="weui-icon-clear"
            @click="clear"
          ></a>
        </div>
        <label
          v-show="!isFocus && !value"
          :for="`search_input_${uuid}`"
          class="weui-search-bar__label"
        >
          <i class="weui-icon-search"></i>
          <span>{{ placeholder || '搜索' }}</span>
        </label>
      </form>
      <a
        href="javascript:"
        class="weui-search-bar__cancel-btn"
        @click="cancel"
      >{{ cancelText || '取消' }}
      </a>
      <slot name="right"></slot>
    </div>
    <div
      v-show="isFixed"
      class="weui-cells vux-search_show"
    >
      <slot></slot>
      <div
        v-for="item in results"
        :key="item.title"
        class="weui-cell weui-cell_access"
        @click="handleResultClick(item)"
      >
        <div class="weui-cell__bd weui-cell_primary">
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uuidMixin from '../../mixins/uuid';

export default {
  name: 'Search',
  mixins: [uuidMixin],
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    cancelText: String,
    value: {
      type: String,
      default: '',
    },
    results: {
      type: Array,
      default() {
        return [];
      },
    },
    autoFixed: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: '0px',
    },
    position: {
      type: String,
      default: 'fixed',
    },
    autoScrollToTop: Boolean,
  },
  data() {
    return {
      onInput: false,
      currentValue: '',
      isCancel: true,
      isFocus: false,
      isFixed: false,
    };
  },
  computed: {
    fixPosition() {
      if (this.isFixed) {
        return this.position === 'absolute' ? 'absolute' : 'fixed';
      }
      return 'static';
    },
  },
  watch: {
    isFixed(val) {
      if (val === true) {
        this.setFocus();
        this.isFocus = true;

        if (this.autoScrollToTop) {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 150);
        }
      }
    },
    value(val) {
      this.currentValue = val;
    },
  },
  created() {
    if (this.value) {
      this.currentValue = this.value;
    }
  },
  methods: {
    emitEvent() {
      this.$nextTick(() => {
        this.$emit('input', this.currentValue);
        this.$emit('on-change', this.currentValue);
      });
    },
    onComposition($event, type) {
      if (type === 'start') {
        this.onInput = true;
      }
      if (type === 'end') {
        this.onInput = false;
        this.emitEvent();
      }
      if (type === 'input') {
        if (!this.onInput) {
          this.emitEvent();
        }
      }
    },
    clear() {
      this.currentValue = '';
      this.emitEvent();
      this.isFocus = true;
      this.setFocus();
      if (this.autoFixed && !this.isFixed) {
        this.isFixed = true;
      }
      this.$emit('on-clear');
    },
    cancel() {
      this.isCancel = true;
      this.currentValue = '';
      this.emitEvent();
      this.isFixed = false;
      this.$emit('on-cancel');
    },
    handleResultClick(item) {
      this.$emit('result-click', item); // just for compatibility
      this.$emit('on-result-click', item);
      this.isCancel = true;
      this.isFixed = false;
    },
    touch() {
      this.isCancel = false;
      if (this.autoFixed) {
        this.isFixed = true;
      }
      this.$emit('on-touch');
    },
    setFocus() {
      this.$refs.input.focus();
    },
    setBlur() {
      this.$refs.input.blur();
    },
    onFocus() {
      this.isFocus = true;
      this.$emit('on-focus');
      this.touch();
    },
    onBlur() {
      this.isFocus = false;
      this.$emit('on-blur');
    },
  },
};
</script>

<style lang="less">
@import '../../styles/weui/icon/weui_icon_font';
@import '../../styles/weui/widget/weui_searchbar/weui_searchbar';
@import '../../styles/weui/widget/weui_cell/weui_cell_global';
@import '../../styles/weui/widget/weui_cell/weui_access';

.vux-search-fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(5px);
}
.vux-search-box {
  width: 100%;
}
.weui-cells.vux-search_show {
  margin-top: 0!important;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  max-height: 100%;

  .weui-cell:last-child {
    margin-bottom: 150px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    display: none;
  }
}
.vux-search-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  height: 100%;
  z-index: 5;
}
</style>
