<template>
  <div
    class="vux-loadmore weui-loadmore"
    :class="{'weui-loadmore_line':!showLoading, 'weui-loadmore_dot': !showLoading && !tip}"
  >
    <i
      v-if="showLoading"
      class="weui-loading"
    ></i>
    <span
      v-show="tip || !showLoading"
      class="weui-loadmore__tips"
    >{{ tip }}</span>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',
  props: {
    showLoading: {
      type: Boolean,
      default: true,
    },
    tip: String,
  },
};
</script>

<style lang="less">
@import '../../styles/weui/widget/weui-loading/weui-loading.less';
@import '../../styles/weui/widget/weui_tips/weui-loadmore.less';

.vux-loadmore {

  &.weui-loadmore_line {
    display: flex;
    border-top: 0;

    &:before, &:after {
      position: relative;
      top: -1px;
      flex: 1;
      content: '';
      border-top: 1px solid @weuiLineColorLight;
    }
  }
}
</style>
