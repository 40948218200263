<template>
  <button
    class="vux-swipeout-button"
    :class="{'vux-swipeout-button-primary': type === 'primary', 'vux-swipeout-button-warn': type === 'warn', 'vux-swipeout-button-default': type === 'default'}"
    :style="{width: `${width}px`, backgroundColor: backgroundColor}"
    type="button"
    @click="onButtonClick"
  >
    <slot>{{ text }}</slot>
  </button>
</template>

<script>
export default {
  name: 'SwipeoutButton',
  props: {
    text: String,
    backgroundColor: String,
    type: String,
    width: {
      type: Number,
      default: 80,
    },
  },
  methods: {
    onButtonClick() {
      if (this.$parent.$options._componentTag === 'swipeout-item') {
        this.$parent.onItemClick(this.text);
      }
    },
  },
};
</script>
