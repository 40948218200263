<template>
  <div
    class="vux-check-icon"
    @click="updateValue"
  >
    <icon
      v-show="type === 'default' && value"
      type="success"
    ></icon>
    <icon
      v-show="type === 'plain' && value"
      type="success_circle"
    ></icon>
    <icon
      v-show="!value"
      type="circle"
    ></icon>
    <span><slot></slot></span>
  </div>
</template>

<script>
import Icon from '../icon';

export default {
  name: 'CheckIcon',
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    updateValue() {
      this.$emit('update:value', !this.value);
    },
  },
};
</script>

<style lang="less">
@import '../../styles/variable.less';

.vux-check-icon {
  display: inline-block;
}
.vux-check-icon > span {
  line-height: 23px;
  color: #222;
  vertical-align: middle;
}
.vux-check-icon > .weui-icon-success:before, .vux-check-icon > .weui-icon-success-circle:before {
  color: @check-icon-color-checked;
}
</style>
