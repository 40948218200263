<template>
  <i class="weui-loading"></i>
</template>

<script>
export default {
  name: 'InlineLoading',
};
</script>

<style lang="less">
@import '../../styles/weui/widget/weui-loading/weui-loading.less';
</style>
