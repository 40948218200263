<template>
  <div
    class="vux-x-dialog"
    :class="{'vux-x-dialog-absolute': layout === 'VIEW_BOX'}"
  >
    <transition :name="maskTransition">
      <div
        v-show="show"
        class="weui-mask"
        :style="maskStyle"
        @click="hide"
      ></div>
    </transition>
    <transition :name="dialogTransition">
      <div
        v-show="show"
        :class="dialogClass"
        :style="dialogStyle"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import preventBodyScrollMixin from '../../mixins/prevent-body-scroll';

export default {
  name: 'XDialog',
  mixins: [preventBodyScrollMixin],
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    maskTransition: {
      type: String,
      default: 'vux-mask',
    },
    maskZIndex: [String, Number],
    dialogTransition: {
      type: String,
      default: 'vux-dialog',
    },
    dialogClass: {
      type: String,
      default: 'weui-dialog',
    },
    hideOnBlur: Boolean,
    dialogStyle: Object,
    scroll: {
      type: Boolean,
      default: true,
      validator(val) {
        /* istanbul ignore if */
        if (process.env.NODE_ENV === 'development' && val === false) {
          console.warn('[VUX warn] x-dialog:scroll 已经废弃。如果你是 100% 布局，请参照文档配置 $layout 以实现阻止滚动');
        }
        return true;
      },
    },
  },
  data() {
    return {
      layout: '',
    };
  },
  computed: {
    maskStyle() {
      if (typeof this.maskZIndex !== 'undefined') {
        return {
          zIndex: this.maskZIndex,
        };
      }
      return null;
    },
  },
  watch: {
    show(val) {
      this.$emit('update:show', val);
      this.$emit(val ? 'on-show' : 'on-hide');
      if (val) {
        this.addModalClassName();
      } else {
        this.removeModalClassName();
      }
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      if (window.VUX_CONFIG && window.VUX_CONFIG.$layout === 'VIEW_BOX') {
        this.layout = 'VIEW_BOX';
      }
    }
  },
  methods: {
    shouldPreventScroll() {
      // hard to get focus on iOS device with fixed position, so just ignore it
      const iOS = /iPad|iPhone|iPod/i.test(window.navigator.userAgent);
      const hasInput = this.$el.querySelector('input') || this.$el.querySelector('textarea');
      if (iOS && hasInput) {
        return true;
      }
    },
    hide() {
      if (this.hideOnBlur) {
        this.$emit('update:show', false);
        this.$emit('change', false);
        this.$emit('on-click-mask');
      }
    },
  },
};
</script>

<style lang="less">
@import '../../styles/transition.less';
@import '../../styles/weui/widget/weui_tips/weui_mask';
@import '../../styles/weui/widget/weui_tips/weui_dialog';
@import '../../styles/vux-modal.css';

.vux-x-dialog-absolute .weui-dialog {
  position: absolute;
}
</style>
