<template>
  <cell
    :title="title"
    :value="currentValue"
    :is-link="!readonly"
    :value-align="valueAlign"
    :border-intent="borderIntent"
    @click.native="show"
  >
    <span
      v-if="!displayValue && placeholder"
      class="vux-cell-placeholder"
    >{{ placeholder }}</span>
    <span
      v-if="displayValue"
      class="vux-cell-value"
    >{{ displayValue }}</span>
    <span slot="icon">
      <slot name="icon"></slot>
    </span>
    <div v-transfer-dom>
      <popup
        v-model="showPopup"
        style="background-color: #fff;"
        @on-hide="$emit('on-hide')"
        @on-show="$emit('on-show')"
      >
        <slot
          name="popup-header"
          :options="options"
          :value="currentValue"
        ></slot>
        <radio
          v-model="currentValue"
          :options="options"
          :fill-mode="false"
          @on-change="onValueChange"
        >
          <template
            slot="each-item"
            slot-scope="props"
          >
            <slot
              name="each-item"
              :icon="props.icon"
              :label="props.label"
              :index="props.index"
            >
              <p>
                <img
                  v-show="props.icon"
                  class="vux-radio-icon"
                  :src="props.icon"
                >
                <span class="vux-radio-label">{{ props.label }}</span>
              </p>
            </slot>
          </template>
        </radio>
      </popup>
    </div>
  </cell>
</template>

<script>
import Cell from '../cell';
import Popup from '../popup';
import Radio from '../radio';
import radioProps from '../radio/props';
import cellProps from '../cell/props';
import TransferDom from '../../directives/transfer-dom';


const _cellProps = cellProps();
delete _cellProps.value;

export default {
  name: 'PopupRadio',
  components: {
    Popup,
    Radio,
    Cell,
  },
  directives: {
    TransferDom,
  },
  props: {
    placeholder: String,
    readonly: Boolean,
    ..._cellProps,
    ...radioProps(),
  },
  data() {
    return {
      showPopup: false,
      currentValue: this.value,
    };
  },
  computed: {
    displayValue() {
      if (!this.options.length) {
        return '';
      }
      if (typeof this.options[0] === 'object') {
        const match = Array.prototype.find.call(this.options, option => {
          return option.key === this.currentValue;
        });
        if (match) {
          return match.value;
        }
      }
      return this.currentValue;
    },
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      this.$emit('input', val);
      this.$emit('on-change', val);
    },
  },
  methods: {
    onValueChange() {
      this.hide();
    },
    show() {
      if (!this.readonly) {
        this.showPopup = true;
      }
    },
    hide() {
      this.showPopup = false;
    },
  },
};
</script>

<style>
.vux-popup-radio-popup {
  background-color: #fff;
}
</style>
