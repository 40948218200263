import { getMaxDay } from './util';

function getYears(startDate, endDate) {
  let startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const rs = [];
  while (startYear <= endYear) {
    rs.push(startYear);
    startYear++;
  }
  return {
    minYear: rs[0],
    maxYear: rs[rs.length - 1],
  };
}

function getMonths(startDate, endDate, year) {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const endMonth = endDate.getMonth() + 1;
  let start = 1;
  let end = 12;
  if (year === startYear) {
    start = startMonth;
  }
  if (year === endYear) {
    end = endMonth;
  }
  return {
    minMonth: start,
    maxMonth: end,
  };
}

function getDays(startDate, endDate, year, month) {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const endMonth = endDate.getMonth() + 1;
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();

  let start = 1;
  let end = getMaxDay(year, month);

  if (year === startYear && month === startMonth) {
    start = startDay;
  }
  if (year === endYear && month === endMonth) {
    end = endDay;
  }
  return {
    minDay: start,
    maxDay: end,
  };
}

export {
  getYears,
  getMonths,
  getDays,
};
