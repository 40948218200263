const { map } = Array.prototype;
const { find } = Array.prototype;

export default function (value, list, delimiter) {
  if (value && !list.length) {
    return '';
  }
  if (!delimiter) {
    delimiter = ' ';
  }

  let rs = map.call(value, (one, index) => {
    if (list.length && Object.prototype.toString.call(list[0]) === '[object Array]') {
      return find.call(list[index], item => {
        return item.value === one;
      });
    } else {
      return find.call(list, item => {
        return item.value === one;
      });
    }
  });
  rs = rs.filter(one => {
    return typeof one !== 'undefined';
  });
  return map.call(rs, one => {
    return one.name;
  }).join(delimiter).replace('--', '');
}

