<template>
  <div class="vux-confirm">
    <x-dialog
      v-model="showValue"
      :dialog-class="theme === 'android' ? 'weui-dialog weui-skin_android' : 'weui-dialog'"
      :mask-transition="maskTransition"
      :dialog-transition="theme === 'android' ? 'vux-fade' : dialogTransition"
      :hide-on-blur="hideOnBlur"
      :mask-z-index="maskZIndex"
      @on-hide="$emit('on-hide')"
    >
      <div
        v-if="!!title"
        class="weui-dialog__hd"
        :class="{'with-no-content': !showContent}"
      >
        <strong class="weui-dialog__title">{{ title }}</strong>
      </div>
      <template v-if="showContent">
        <div
          v-if="!showInput"
          class="weui-dialog__bd"
        >
          <slot><div v-html="content"></div></slot>
        </div>
        <div
          v-else
          class="vux-prompt"
        >
          <input
            ref="input"
            v-model="msg"
            class="vux-prompt-msgbox"
            v-bind="getInputAttrs()"
            :placeholder="placeholder"
            @touchend="setInputFocus"
          >
        </div>
      </template>
      <div class="weui-dialog__ft">
        <a
          v-if="showCancelButton"
          href="javascript:;"
          class="weui-dialog__btn weui-dialog__btn_default"
          @click="_onCancel"
        >{{ cancelText || '取消' }}</a>
        <a
          v-if="showConfirmButton"
          href="javascript:;"
          class="weui-dialog__btn"
          :class="`weui-dialog__btn_${confirmType}`"
          @click="_onConfirm"
        >{{ confirmText || '确定' }}</a>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import XDialog from '../x-dialog';

export default {
  name: 'Confirm',
  components: {
    XDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showInput: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'ios',
    },
    hideOnBlur: {
      type: Boolean,
      default: false,
    },
    title: String,
    confirmText: String,
    cancelText: String,
    maskTransition: {
      type: String,
      default: 'vux-fade',
    },
    maskZIndex: [Number, String],
    dialogTransition: {
      type: String,
      default: 'vux-dialog',
    },
    content: String,
    closeOnConfirm: {
      type: Boolean,
      default: true,
    },
    inputAttrs: Object,
    showContent: {
      type: Boolean,
      default: true,
    },
    confirmType: {
      type: String,
      default: 'primary',
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      msg: '',
      showValue: false,
    };
  },
  watch: {
    value(val) {
      this.showValue = val;
    },
    showValue(val) {
      this.$emit('input', val);
      if (val) {
        if (this.showInput) {
          this.msg = '';
          setTimeout(() => {
            if (this.$refs.input) {
              this.setInputFocus();
            }
          }, 300);
        }
        this.$emit('on-show'); // emit just after msg is cleared
      }
    },
  },
  created() {
    this.showValue = this.show;
    if (this.value) {
      this.showValue = this.value;
    }
  },
  methods: {
    getInputAttrs() {
      return this.inputAttrs || {
        type: 'text',
      };
    },
    setInputValue(val) {
      this.msg = val;
    },
    setInputFocus(evt) {
      if (evt) {
        evt.preventDefault();
      }
      this.$refs.input.focus();
    },
    _onConfirm() {
      if (!this.showValue) {
        return;
      }
      if (this.closeOnConfirm) {
        this.showValue = false;
      }
      this.$emit('on-confirm', this.msg);
    },
    _onCancel() {
      if (!this.showValue) {
        return;
      }
      this.showValue = false;
      this.$emit('on-cancel');
    },
  },
};
</script>

<style lang="less">
@import '../../styles/transition.less';
@import '../../styles/weui/widget/weui_tips/weui_mask';
@import '../../styles/weui/widget/weui_tips/weui_dialog';

.vux-prompt {
  padding-bottom: 1.6em;
}

.vux-prompt-msgbox {
  width: 80%;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 4px 5px;
  appearance: none;
  outline: none;
  font-size: 16px;
}
</style>
