<template>
  <div>
    <canvas
      v-show="type === 'canvas'"
      ref="canvas"
      :style="{
        height: `${size/3.75}vw`,
        width: `${size/3.75}vw`
      }"
      :height="size"
      :width="size"
    ></canvas>
    <img
      v-if="type === 'img'"
      :src="imgData"
      :style="{
        height: `${size/3.75}vw`,
        width: `${size/3.75}vw`
      }"
    >
  </div>
</template>

<script>
import QRCodeImpl from 'qr.js/lib/QRCode';
import ErrorCorrectLevel from 'qr.js/lib/ErrorCorrectLevel';

export default {
  name: 'Qrcode',
  props: {
    value: String,
    size: {
      type: Number,
      default: 160,
    },
    level: {
      type: String,
      default: 'L',
    },
    bgColor: {
      type: String,
      default: '#FFFFFF',
    },
    fgColor: {
      type: String,
      default: '#000000',
    },
    type: {
      type: String,
      default: 'img',
    },
  },
  data() {
    return {
      imgData: '',
    };
  },
  watch: {
    value() {
      this.render();
    },
    size() {
      this.render();
    },
    level() {
      this.render();
    },
    bgColor() {
      this.render();
    },
    fgColor() {
      this.render();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.render();
    });
  },
  methods: {
    render() {
      if (typeof this.value === 'undefined') {
        return;
      }

      const qrcode = new QRCodeImpl(-1, ErrorCorrectLevel[this.level]);
      qrcode.addData(utf16to8(this.value));
      qrcode.make();

      const { canvas } = this.$refs;

      const ctx = canvas.getContext('2d');
      const cells = qrcode.modules;
      const tileW = this.size / cells.length;
      const tileH = this.size / cells.length;
      const scale = (window.devicePixelRatio || 1) / getBackingStorePixelRatio(ctx);
      canvas.width = this.size * scale;
      canvas.height = canvas.width;
      ctx.scale(scale, scale);

      cells.forEach((row, rdx) => {
        row.forEach((cell, cdx) => {
          ctx.fillStyle = cell ? this.fgColor : this.bgColor;
          const w = (Math.ceil((cdx + 1) * tileW) - Math.floor(cdx * tileW));
          const h = (Math.ceil((rdx + 1) * tileH) - Math.floor(rdx * tileH));
          ctx.fillRect(Math.round(cdx * tileW), Math.round(rdx * tileH), w, h);
        });
      });
      if (this.type === 'img') {
        this.imgData = canvas.toDataURL('image/png');
      }
    },
  },
};

function getBackingStorePixelRatio(ctx) {
  return (
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1
  );
}

function utf16to8(str) {
  let out;
  let i;
  let c;
  out = '';
  const len = str.length;
  for (i = 0; i < len; i++) {
    c = str.charCodeAt(i);
    if ((c >= 0x0001) && (c <= 0x007F)) {
      out += str.charAt(i);
    } else if (c > 0x07FF) {
      out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
      out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F));
      out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
    } else {
      out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F));
      out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
    }
  }
  return out;
}
</script>
