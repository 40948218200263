var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('div',{staticClass:"weui-cells__title",style:(_vm.cleanStyle({
      color: _vm.titleColor
    })),domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._t("title"),_c('div',{staticClass:"weui-cells",class:{
      'vux-no-group-title': !_vm.title
    },style:(_vm.cleanStyle({
      marginTop: typeof _vm.gutter === 'number' ? (_vm.gutter + 'px') : _vm.gutter
    }))},[_vm._t("after-title"),_vm._t("default")],2),(_vm.footerTitle)?_c('div',{staticClass:"weui-cells__title vux-group-footer-title",style:(_vm.cleanStyle({
      color: _vm.footerTitleColor
    })),domProps:{"innerHTML":_vm._s(_vm.footerTitle)}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }