<template>
  <div class="vux-swiper-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SwiperItem',
  mounted() {
    this.$nextTick(() => {
      this.$parent.rerender();
    });
  },
  beforeDestroy() {
    const { $parent } = this;
    this.$nextTick(() => {
      $parent.rerender();
    });
  },
};
</script>
